// extracted by mini-css-extract-plugin
export var tileContent = "F_vT d_w d_H d_Z";
export var teamTextLeft = "F_vV d_dv";
export var teamTextCenter = "F_vW d_dw";
export var teamTextRight = "F_vX d_dx";
export var alignLeft = "F_rd d_w d_bz d_fp d_bG d_dv";
export var alignCenter = "F_bP d_w d_bz d_fq d_bD d_dw";
export var alignRight = "F_rf d_w d_bz d_fr d_bH d_dx";
export var teamContainer = "F_vY d_dW";
export var teamContainerFull = "F_vZ d_dT";
export var teamRowWrapper = "F_v0 d_cc";
export var teamTileWrapper = "F_j3 d_j3 d_Z d_cv";
export var teamTileSquareWrapper = "F_v1 d_j4 d_Z d_cv";
export var teamTileRoundWrapper = "F_j4 d_j4 d_Z d_cv";
export var teamTileNoGuttersWrapper = "F_j5 d_j5 d_Z";
export var teamHoverNoGutters = "F_j6 d_j6 d_0 d_w d_H d_bz d_bD d_bP d_bm";
export var teamImageText = "F_kc d_kc d_bl d_w d_by";
export var teamInfoWrapperSquare = "F_v2 d_j7";
export var teamInfoWrapperRound = "F_j7 d_j7";
export var teamInfoWrapper = "F_v3 d_j8 d_0";
export var teamInfoWrapperNoGutters = "F_j9 d_j9 d_w d_H d_bD d_bM d_bJ d_c7";
export var teamImgWrapper = "F_jZ d_jZ";
export var teamImgWrapperAlt = "F_j0 d_j0";
export var teamImgWrapperNoGutters = "F_kb d_kb";
export var teamHeader = "F_v4 d_cw";
export var teamHeaderAlt = "F_v5 d_cw";
export var teamHeaderNoGutters = "F_v6 d_cw d_cD";