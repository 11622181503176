// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "C_vK d_gv d_cs";
export var heroHeaderCenter = "C_gw d_gw d_cs d_dw";
export var heroHeaderRight = "C_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "C_vL d_gr d_cw";
export var heroParagraphCenter = "C_gs d_gs d_cw d_dw";
export var heroParagraphRight = "C_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "C_vM d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "C_vN d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "C_vP d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "C_vQ d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "C_vR d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "C_tS z_tS";
export var heroExceptionNormal = "C_tT z_tT";
export var heroExceptionLarge = "C_tV z_tV";
export var Title1Small = "C_tx z_tx z_s2 z_s3";
export var Title1Normal = "C_ty z_ty z_s2 z_s4";
export var Title1Large = "C_tz z_tz z_s2 z_s5";
export var BodySmall = "C_tJ z_tJ z_s2 z_tl";
export var BodyNormal = "C_tK z_tK z_s2 z_tm";
export var BodyLarge = "C_tL z_tL z_s2 z_tn";