// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "G_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "G_fR d_fR d_bz d_bJ";
export var menuDesign6 = "G_v7 d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "G_v8 d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "G_v9 d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "G_wb d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "G_wc d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "G_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "G_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "G_wd";
export var navbarItem = "G_nb d_bx";
export var navbarLogoItemWrapper = "G_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "G_wf d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "G_wg d_gd d_by d_Z d_bs";
export var burgerToggle = "G_wh d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "G_wj d_gd d_by d_Z d_bs";
export var burgerInput = "G_wk d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "G_wl d_f3 d_w d_H";
export var burgerLine = "G_wm d_f1";
export var burgerMenuDesign6 = "G_wn d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "G_wp d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "G_wq d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "G_wr d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "G_ws d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "G_wt d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "G_wv d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "G_ww d_bC d_bP";
export var compact = "G_wx";
export var navDivided = "G_wy";
export var staticBurger = "G_wz";
export var menu = "G_wB";
export var navbarDividedLogo = "G_wC";
export var nav = "G_wD";
export var fixed = "G_wF";